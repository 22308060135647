import Swal from 'sweetalert2';

// import Swal from 'sweetalert2/dist/sweetalert2.js'
//
// import 'sweetalert2/src/sweetalert2.scss'


function purchaseSwal( investorObj ) {

  Swal.fire({
    title: '',
    html: `<p>再次確認密碼</p><input type="password" id="password" class="mx-auto form-control form-control-sm swal2-input" placeholder="Password">
    <p class='mt-3 mb-1'>您有${investorObj.couponsLeft}張VIP卷，是否於本次使用?</p>
    <div class="text-center">
    <div class="custom-control custom-radio custom-control-inline">
      <input type="radio" id="ansYes" name="radioInput" class="custom-control-input">
      <label class="custom-control-label" for="ansYes"> 是 </label>
    </div>
    <div class="custom-control custom-radio custom-control-inline">
      <input type="radio" id="ansNo" name="radioInput" class="custom-control-input" checked >
      <label class="custom-control-label" for="ansNo" > 否 </label>
    </div>
    </div>
    `,
    confirmButtonText: '確認',
    focusConfirm: false,

  })
  .then((result) => {
    const password = Swal.getPopup().querySelector('#password').value
    const ansYeschecked = Swal.getPopup().querySelector('#ansYes').checked
    const ansNochecked = Swal.getPopup().querySelector('#ansNo').checked
  
  
    const token = document.getElementsByName('csrf-token')[0].getAttribute('content')
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios({
      method: 'post',
      url: '/validate_pw.json',
      data: {
        user: {
          id: investorObj.userId,
          password: password
        }
      }
    })
    .then( (response) => {
      const isSuccess = response.status == 200
      const couponsNoLeft = Number(investorObj.couponsLeft) < 1
      const vipCoupon = ansYeschecked ? '1' : '2'
      if (isSuccess && couponsNoLeft && ansYeschecked){
       Swal.fire(`VIP券數量不足`.trim())
      }else if (isSuccess){
        console.log('200');
        axios({
          method: 'post',
          url: investorObj.purchaseUrl,
          data: {
            contract_id: investorObj.contractId,
            web_user_id: investorObj.userId,
            investor_id: investorObj.investorId,
            purchase_amount: investorObj.amount,
            vip_coupon: vipCoupon
          }
        })
        .then((res) => {
          console.log('after purchase', res)
         const purchased = res.status == 200
          if(purchased){
            const token = document.getElementsByName('csrf-token')[0].getAttribute('content')
            axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            axios({
              method: 'post',
              url: '/purchase_notify',
              data: {
                contract_id: res.data['display_number']
              }
            }).then((res) =>{
              console.log('purchase_notify ss')
            })
            Swal.fire({
              title: res.data['message'].trim(),
              html: '<p class=\'text-left\'>投資人您好，感謝您認購合約編號 ' + res.data['display_number'] +' 投資案件， Finterest平台客服人員會儘快與您聯繫，說明後續的確認與核對程序。</p>' +
              '<p class=\'text-left\'>官方LINE@：<a href="https://lin.ee/43bruCL">https://lin.ee/43bruCL</a> <br> 投資說明：<a href="https://finterest.com.tw/pages/1">https://finterest.com.tw/pages/1</a> </p> ' +
            '謝謝'}
            ).then(function() {
                window.location.href = '/investments';
            });
          }else{
            Swal.fire(res.data['message'].trim()).then(function() {
                window.location.href = '/investments';
            });
          }
        })
      } else {
       Swal.fire(`認購失敗，密碼填寫錯誤`.trim())
      }
    })

  
  })

}

const showError = Swal.mixin({
  icon: 'warning',
  showConfirmButton:  false,
  timer: 2200
})


window.showError = showError;
window.purchaseSwal = purchaseSwal;


